<template>
  <h1>Text Encode / Decode</h1>
  <el-row style="margin-bottom: 20px;">
    <el-col
      :span="24"
    >
      <div
        class="grid-content"
        style="padding: 20px; background-color: #1a1a1a"
      >
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item
            label="Type"
            class="item-label"
          >
            <el-radio-group
              v-model="type"
              @change="onInputText"
            >
              <el-radio-button label="URL" />
              <el-radio-button label="Base64" />
              <el-radio-button
                label="Html Entity (Name/Number(decimal/hex))"
                disabled
              />
              <el-radio-button
                label="Unicode Escape Sequence"
                disabled
              />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="Mode"
            class="item-label"
          >
            <el-radio-group
              v-model="mode"
              @change="onInputText"
            >
              <el-radio
                label="Encode"
                model-value="encode"
              />
              <el-radio
                label="Decode"
                model-value="decode"
              />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="Input"
            class="el-form-item-label"
          >
            <el-input
              v-model="input"
              type="textarea"
              :rows="5"
              placeholder="Please input"
              @input="onInputText"
            />
          </el-form-item>
          <el-form-item>
            <div style="text-align: center;">
              <i
                class="el-icon-arrow-down"
                style="font-size: 2em;"
              />
            </div>
          </el-form-item>
          <el-form-item
            label="Output"
          >
            <el-input
              v-model="output"
              type="textarea"
              :rows="5"
              readonly
            />
          </el-form-item>
          <el-form-item>
            <el-button
              style="float: right"
              type="primary"
              @click="onClickCopyButton"
            >
              Copy
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-bottom: 20px;">
    <el-col :span="24">
      <el-table
        :data="tableData"
        style="width: 100%;"
        :header-cell-style="{ background: '#121212'}"
        :cell-style="{ background: '#1a1a1a' }"
        :border="false"
      >
        <el-table-column
          prop="date"
          label="Date"
          width="120"
        />
        <el-table-column
          prop="name"
          label="Version"
          width="80"
        />
        <el-table-column
          prop="description"
          label="Description"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<script type="ts">
//import {defineComponent, ref} from "vue";
import {defineComponent, ref} from "vue";
import {Buffer} from "buffer";

export default defineComponent ({
  name: "TextEncode",
  setup() {
    return {
      type: ref('URL'),
      mode: ref('Encode'),
      input: ref(''),
      output: ref(''),
      tableData: [{
        date: '2021/03/19',
        name: '0.9.2',
        description: 'added clipboard function.'
      }, {
        date: '2021/03/17',
        name: '0.9.1',
        description: 'init'
      }]
    }
  },
  methods: {
    onInputText() {
      let type = this.type
      let mode = this.mode
      let value = this.input

      console.log(mode);
      if (value) {
        switch (type) {
          case 'URL':
            if (mode === 'Encode') {
              return this.output = encodeURI(value)
            }
            if (mode === 'Decode') {
              return this.output = decodeURI(value)
            }
            break;
          case 'Base64':
            if (mode === 'Encode') {
              return this.output = Buffer.from(value).toString('base64')
            }
            if (mode === 'Decode') {
              return this.output = Buffer.from(value, 'base64').toString()
            }
            break;
          case 'Entity':
            // todo:
            break;
        }


      } else {
        this.output = ''
      }
    },
    onClickCopyButton() {
      let text = this.output
      navigator.clipboard.writeText(text).catch((e) => {
        console.error(e)
      })
      this.$message({
        message: 'Copied.',
        type: 'success'
      })
    }

  }
})
</script>

<style scoped>
/*.el-row {*/
/*  margin-bottom: 20px;*/
/*&:last-child {*/
/*   margin-bottom: 0;*/
/* }*/
/*}*/
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
h1 {
  color: white;
}

.grid-content form.el-form .el-form-item label {
  color: white;
}
.el-form-item-label .el-form-item__label {
  color: white;
}


.item-label label { color: wheat; }

.el-table .version-table-row {
  background-color: pink;
}
.el-table .version-table-header {
  background-color: lightblue;
  color: #fff;
  font-weight: 500;
}


</style>